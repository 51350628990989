/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './productViewStyle';
import React, { useState, useEffect, Fragment } from 'react';
import env from '../../../env';
import utils from '../../../utils';
import { ErrorPopup, Loading, Button, ErrorMessage, useGet, usePost, useRouter } from '../../../components';
import { ViewPartial } from '../../../cms/pages/partials/viewPartial';
//import { CommonProductBottom } from '../../../cms/pages/partials/commonProductBottom';
//import { ProductTile } from '../productList/productTile';
import FsLightbox from 'fslightbox-react';
import { MetaTag } from '../../../components';
import { AddToCart } from './addToCart';
import Slider from "react-slick";
import { MdFavorite } from "react-icons/md";
//import { debounce } from '../../../lib/utils-core/debounce';
import { gtm } from '../../../lib/tracking';
//import { FacebookShareButton, FacebookIcon, PinterestShareButton, PinterestIcon } from "react-share";
import { createPortal } from "react-dom";
import { getEnquiryFormModel } from './enquiryFormModel';
import { useForm } from '../../../form';
import { ProductPagePartial } from '../../../cms/pages/partials/productPagePartial';
import { useStore } from '../../../store';
import { Document, Page } from 'react-pdf/dist/entry.webpack';

function Portal({ children }) {
  const [el] = useState(document.createElement('div'));
  useEffect(() => {
    let mount = document.getElementById("portal-root");
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

export function ProductView(props) {

  const { query } = useRouter();
  const [loaded, setLoaded] = React.useState(false);
  const get = useGet();
  const productCode = query.id || '';
  const category = query.category || '';
  const slug = query.slug || '';
  //console.log(category, slug, productCode)

  React.useEffect(() => {
    console.log('productview use effect');
    //const url = productCode ? `/api/product/getProduct/${productCode}` : `/api/product/getProductByUrl?category=${category}&slug=${slug}`
    const url = `/api/product/getProduct/${slug}`;
    get.send(env.apiBase + url);
    setLoaded(false);

    //pdfjs.GlobalWorkerOptions.workerSrc

    // eslint-disable-next-line
  }, [productCode, slug]);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  const data = get.response;

  if (get.done() && !loaded) {
    setLoaded(true);
    const pageUrl = productCode ? `/product/${productCode}` : `/products/${category}/${slug}`;
    const pageTitle = data.totalCnt === 0 ? 'No product found' : data.productName;
    gtm.pageView(pageUrl, pageTitle);
  }

  if (data.totalCnt === 0) return <div>No product found.</div>
  return (
    <React.Fragment>

      {/* <div className="single-banner banner-item bannerSize--thin"
        style={{ backgroundImage: utils.css.bgUrlStyle(utils.site.resourcePath('/sites/beehive-react/media/banners/adobestock_221175640.jpg')) }}>
      </div> */}

      <div className="product__banner" css={style.product__banner}></div>
      <Product product={data} openAddEnquiryForm={props.openAddEnquiryForm} />
      {/* <CommonProductBottom /> */}
      <ProductPagePartial />
      <ViewPartial />

    </React.Fragment>
  );
}

function Product({ product, openAddEnquiryForm }) {

  //const bgUrl = "/sites/controlair/media/banners/control-air-banner-5.jpg";

  //product.productImages = [];
  // const mainPhoto = product.productImages.length > 0 ? product.productImages[0] : { imageUrl: '/assets/product-empty.png', alt: product.productName };
  //const mainPhoto = {imageUrl: utils.site.resourcePath('/sites/beehive-react/media/products/coastal-flora-1k-hexjar.jpg'), alt: product.productName};

  const mainPhoto = product.productImages.length > 0 ?
    { imageUrl: product.productImages[0].imageUrl, alt: product.productImages[0].alt ? product.productImages[0].alt : product.productName } :
    { imageUrl: '/assets/logos/logo.png', alt: product.productName };

  const allPhotos = product.productImages || [];
  //console.log("aaa", allPhotos);
  //const otherPhotos = product.productImages.length > 0 ? product.productImages.filter(x => !x.isPackage) : [];
  const popupImages = allPhotos.map(x => x.imageUrl);

  const [heroImage, setHeroImage] = React.useState(mainPhoto);
  const [openPopup, setOpenPopup] = useState(false);

  const [pdfViewPath, setPdfViewPath] = React.useState();
  const [pdfViewNumPages, setPdfViewNumPages] = React.useState();
  const [pdfViewWidth, setPdfViewWidth] = React.useState(window.innerWidth);
  const [pdfViewScale, setPdfViewScale] = React.useState(false);

  const [toggler, setToggler] = React.useState(false);
  //const [popupImages, setPopupImages] = React.useState();
  const [popupIndex, setPopupIndex] = React.useState(0);

  //const shareUrl = utils.site.resourcePath('/product/' + product.productSlug);
  //const heroImageUrl = utils.site.resourcePath(mainPhoto.imageUrl);

  const enquiryFormModel = getEnquiryFormModel({});
  const enquiryForm = useForm(enquiryFormModel, { usePlaceholder: true });

  const enquiryFormRender = (name) => enquiryForm.renderControl(name, null);
  const post = usePost();
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const [variantOptions, setVariantOptions] = React.useState();
  const [enquiryFormQty, setEnquiryFormQty] = React.useState(1);

  const [favorite, setFavorite] = React.useState(() => {
    if (localStorage.favorites.includes(product.productId)) {
      return true;
    }
    return false;
  });

  const { dispatch } = useStore();

  function openPopupImage(imageUrl) {
    const inx = utils.array.findIndex(popupImages, x => x === imageUrl);
    setPopupIndex(inx >= 0 ? inx : 0)
    setToggler(!toggler)
  }

  function handleClick(e) {

    if (window.innerWidth <= 900) {
      e.preventDefault();
      e.stopPropagation();
      const link = e.target.closest('a').getAttribute('href');
      if (link && link !== '' && link.includes('.pdf')) {
        const link = e.target.closest('a').getAttribute('href');
        setPdfViewPath(link);
        setPdfViewScale(false);
        document.body.style.overflow = "hidden";
      }
    }

  }

  function closePdfViewer() {
    setPdfViewPath(null);
    setPdfViewNumPages(null);
    document.body.style.overflow = "unset";
  }

  function changePdfViewScale() {
    setPdfViewScale(!pdfViewScale);
  }

  function onDocumentLoadSuccess({ numPages }) {

    const result = [];
    for (let i = 0; i < numPages; i++) {
      result.push(i);
      // if(i === 19){
      //   break;
      // }
    }
    
    // const _pages = Math.floor(numPages / 15);
    // const result = [];
    // for (let i = 0; i <= _pages; i++) {
    //   result.push({ pageItem: i, items: [], numPages : numPages });
    // }
    // for (let i = 0; i < numPages; i++) {
    //   result[Math.floor(i / 15)].items.push(i);
    // }

    setPdfViewNumPages(result);
  }

  var settings = {
    dots: false,
    infinite: allPhotos.length > 2 ? true : false,
    speed: 1500,
    autoplaySpeed: 3000,
    //fade: true,
    autoplay: false,
    slidesToShow: allPhotos.length >= 3 ? 3 : allPhotos.length,
    slidesToScroll: 1
  };

  const slides = allPhotos.map((photo, index) => <div css={style.productViewImage} key={index}
    className={`productView__image ${heroImage.imageUrl === photo.imageUrl ? 'current' : ''}`}>
    <div css={style.productViewImageBg} className={`productView__imageBg ${heroImage.imageUrl === photo.imageUrl ? 'current' : ''}`}
      style={{
        backgroundImage: utils.css.bgUrlStyle(photo.imageUrl)
      }}
      onClick={() =>
        setHeroImage(photo)}
    >
    </div>
    {/* <img src={utils.site.resourcePath(photo.imageUrl)} alt={photo.alt} onClick={() => setHeroImage(photo)} /> */}
  </div>
  );

  function onSubmit(e) {
    enquiryForm.validateForm(e, () => {
      const value = { ...enquiryForm.getValue(), productName: product.productName, emailReceiver: '' };
      //var files = form.getFiles();
      var formData = new FormData();
      formData.append('jsonData', JSON.stringify(value));
      //post.send(env.apiBase + "/api/contact/send", value);
      post.sendFormData(env.apiBase + "/api/cart/sendenquire", formData);
      setSendingStatus('pending');
    })
    //console.log('submitting');
  }

  function addToQuoteFunction(type = null) {

    let variantValue = '';

    for (let i in variantOptions || []) {
      if (i > 0) {
        variantValue = variantValue + ', ';
      }
      variantValue = variantValue + variantOptions[i].name + ' : ' + variantOptions[i].value;
    }

    // if (document.cookie.split(";").some((item) => item.trim().startsWith("add_to_quote="))) {
    //   let cookieValue = document.cookie.split("; ").find((row) => row.startsWith("add_to_quote="))?.split("=")[1];
    //   if (cookieValue && cookieValue !== '') {
    //     if (!cookieValue.includes(product.productId)) {

    //       cookieValue = cookieValue + "|" + product.productId
    //         + "::" + product.thumbnailUrl
    //         + "::" + product.productName
    //         + "::" + variantValue
    //         + "::" + (product.productId
    //           + "_"
    //           + (Math.random() * 1000))
    //         + "::" + "";

    //       document.cookie = `add_to_quote=${cookieValue}; expires=Session; path=/`;
    //     }
    //     else {
    //       if (!cookieValue.includes(variantValue)) {
    //         cookieValue = cookieValue + "|" + product.productId + "::" + product.thumbnailUrl + "::" + product.productName + "::" + variantValue + "::" + (product.productId + "_" + (Math.random() * 1000));
    //         document.cookie = `add_to_quote=${cookieValue}; expires=Session; path=/`;
    //       }
    //     }
    //   }
    //   else {
    //     document.cookie = `add_to_quote=${product.productId}::${product.thumbnailUrl}::${product.productName}::${variantValue}::${(product.productId + "_" + (Math.random() * 1000))}; expires=Session; path=/`;
    //   }
    // }
    // else {
    //   document.cookie = `add_to_quote=${product.productId}::${product.thumbnailUrl}::${product.productName}::${variantValue}::${(product.productId + "_" + (Math.random() * 1000))}; expires=Session; path=/`;
    // }

    let cookieValue = document.cookie.split("; ").find((row) => row.startsWith("add_to_quote="))?.split("=")[1];
    let _value = (cookieValue && cookieValue !== '') ? cookieValue.split("|") : [];
    let _valueResult = [];

    let checkNewItem = true;

    for (let i in _value) {
      const _item = _value[i].split("::");
      const _valueO = {
        productId: _item[0],
        productImg: _item[1],
        productName: _item[2],
        productVal: _item[3],
        uniqueId: _item[4],
        enquiryFormQty: _item[5]
      };

      _valueResult.push(_valueO);

      if (_valueO.productId === product.productId && _valueO.productVal === variantValue) {
        _valueO.enquiryFormQty = parseInt(_valueO.enquiryFormQty) + parseInt(enquiryFormQty);
        checkNewItem = false;
        break;
      }
    }

    if (checkNewItem && checkNewItem === true) {
      _valueResult.push({
        productId: product.productId,
        productImg: product.thumbnailUrl,
        productName: product.productName,
        productVal: variantValue,
        uniqueId: product.productId + "_" + (Math.random() * 1000),
        enquiryFormQty: enquiryFormQty
      });
    }

    cookieValue = '';

    for (let i in _valueResult) {

      if (i > 0) {
        cookieValue = cookieValue + "|";
      }

      cookieValue = cookieValue
        + _valueResult[i].productId + "::"
        + _valueResult[i].productImg + "::"
        + _valueResult[i].productName + "::"
        + _valueResult[i].productVal + "::"
        + _valueResult[i].uniqueId + "::"
        + _valueResult[i].enquiryFormQty;
    }

    document.cookie = `add_to_quote=${cookieValue}; expires=Session; path=/`;
    dispatch({ type: 'SET_STATE', payload: { addToQuote: _valueResult.length } });

    if (type && type === 'get_quote') {
      openAddEnquiryForm();
    }

  }

  if (post.done() && sendingStatus === 'pending') {
    //var value = enquiryForm.getValue();
    //gtm.sendEvent('contactFormSubmitted', {contactEmail: value.email} )
    enquiryForm.reset(enquiryFormModel);
    setSendingStatus(null);
    setTimeout(() => {
      setOpenPopup(false);
      document.body.style.overflow = "unset";
    }, 1500);
  }


  useEffect(() => {

    const portrait = window.matchMedia("(orientation: portrait)");
    portrait.addEventListener("change", function (e) {
      if (e.matches) {
        // Portrait mode
        //console.log("111");
      } else {
        // Landscape
        //console.log("222");
      }
      setPdfViewWidth(window.innerWidth);
    });

  }, []);

  return (
    <Fragment>
      {/* <div className="cms_item single-banner banner-item bannerSize--thin" style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl), backgroundPositionY: '50%' }}></div> */}
      <div className="contentPanel productViewPanel" css={style.productViewPanel}>
        <Portal>
          {
            openPopup &&

            <div className="product__enquiry__form__container" css={style.enquiryFormContainer}>
              <form className="contactForm">
                <div className="closeButton" onClick={() => {
                  setOpenPopup(false);
                  document.body.style.overflow = "unset";
                }}>
                  CLOSE
                </div>

                <div className="enquireNowText">
                  {/* Enquire  */}
                </div>

                <div className='row'>
                  <div className='col-12 col-md-6'>
                    <div>
                      <img src={product.thumbnailUrl} style={{ width: '100%' }} alt='product img' />
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>

                    <div className="form-row">
                      <div className="col-12 mb-2">{enquiryFormRender('name')}</div>
                      <div className="col-12 mb-2">{enquiryFormRender('phone')}</div>
                      <div className="col-12 mb-2">{enquiryFormRender('email')}</div>
                    </div>

                    <div className="form-row">
                      <div className="col-12 mb-2">{enquiryFormRender('enquiry')}</div>
                    </div>

                    <div className="form-row">
                      <div className="col-12 mb-2">
                        <div className="actions" style={{ textAlign: 'left' }}>
                          {/* onClick={onSubmit} status={post.status} */}
                          <Button onClick={onSubmit} status={post.status} > SEND ENQUIRY </Button>
                          <ErrorMessage errors={post.errors} />
                          {post.status === 'done' && 'Successfully sent!'}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </form>

            </div>
          }

          {
            pdfViewPath &&
            <PdfViewItem
              pdfViewPath={pdfViewPath}
              pdfViewNumPages={pdfViewNumPages}
              pdfViewWidth={pdfViewWidth}
              pdfViewScale={pdfViewScale}
              onDocumentLoadSuccess={onDocumentLoadSuccess}
              closePdfViewer={closePdfViewer}
              changePdfViewScale={changePdfViewScale} />
            // <Fragment>
            //   <div className="pdf__viewer__bg" css={style.pdf__viewer__bg}></div>
            //   <div className="pdf__viewer__view" css={style.pdf__viewer__view}>

            //     <div className="pdf__viewer__view__bar" css={style.pdf__viewer__view__bar}>
            //       <div onClick={closePdfViewer}>CLOSE</div>
            //       {
            //         pdfViewNumPages &&
            //         <a className='pdf__viewer__view__bar__pdf__download' href={pdfViewPath} download> DOWNLOAD </a>
            //       }

            //     </div>
            //     <div className="pdf__viewer__view__contetns" css={style.pdf__viewer__view__contetns}>

            //       <div className='pdf__viewer__view__contetns__des' css={style.pdf__viewer__view__contetns__des}>
            //         <Document file={pdfViewPath}
            //           onLoadSuccess={onDocumentLoadSuccess}>

            //           {
            //             pdfViewNumPages &&
            //             pdfViewNumPages.map((item, index) => {
            //               return (
            //                 <Page key={index} pageIndex={index} scale={2.0} width={pdfViewWidth} className='pdf__viewer__view__page' css={style.pdf__viewer__view__page}>
            //                   <div className='pdf__viewer__view__index' css={style.pdf__viewer__view__index}>{(index + 1) + ' of ' + pdfViewNumPages.length}</div>
            //                 </Page>
            //               )
            //             })
            //           }

            //         </Document>
            //       </div>

            //     </div>
            //   </div>
            // </Fragment>
          }

        </Portal>

        <FsLightbox
          toggler={toggler}
          sources={popupImages}
          sourceIndex={popupIndex}
        />
        <MetaTag data={getMetaTag(product, mainPhoto)} />

        <div className="contentPanel__bg"></div>
        <div className="container">

          <div className="productView">
            <div itemScope={true} itemType="http://schema.org/Product">

              <meta itemProp="name" content={product.productName} />

              <div itemProp="brand" itemType="http://schema.org/Brand" itemScope>
                <meta itemProp="name" content={env.siteName} />
              </div>

              {product.description && <meta itemProp="description" content={product.description} />}

              {heroImage && heroImage.imageUrl && <link itemProp="image" href={env.baseOrigin + heroImage.imageUrl} />}

              <div className="row">
                <div className="col-md-10" css={style.productPageCol}></div>

                <div className="col-md-2" css={style.productPageCol} style={{ textAlign: 'right' }}>
                  <MdFavorite css={style.productFavorite} className={`${favorite === true ? 'checked' : ''}`} onClick={() => {
                    setFavorite(pre => {
                      let _localStorageFavorites = localStorage.favorites.replace(product.productId + "|", "");
                      let _new = !pre;
                      if (_new === true) {
                        _localStorageFavorites = _localStorageFavorites + product.productId + "|";
                      }
                      localStorage.setItem("favorites", _localStorageFavorites);
                      return _new;
                    });
                  }}></MdFavorite>
                </div>

                <div className="col-md-6 col-12" css={style.productPageCol}>

                  <div css={style.heroImageContainer} className="productView__hero__container">
                    <img css={style.heroImage} className="productView__hero" src={heroImage.imageUrl} alt={heroImage.alt} onClick={() => openPopupImage(heroImage.imageUrl)} />
                  </div>

                  {
                    (allPhotos && allPhotos.length > 1) &&
                    <div css={style.productViewImages} className="productView__images">
                      {/* {slides} */}

                      {
                        utils.site.isMobile() ?
                          <Slider {...settings} >
                            {slides}
                          </Slider> :
                          <div className='productView__images__container' css={style.productView__images__container}>
                            {slides}
                          </div>
                      }
                    </div>
                  }

                </div>
                <div className="col-md-6 col-12" css={style.productPageCol}>

                  <h1 className="productView__title" css={style.productViewTitle}>
                    <strong>{product.productName}</strong>
                  </h1>

                  <div className='product__view__shot__des' css={style.product__view__shot__des}>
                    {product.shortDesc}
                  </div>

                  <div css={style.desc} dangerouslySetInnerHTML={{ __html: product.description }} onClick={(e) => { handleClick(e) }} ></div>

                  {/* {
                    product.productStatus === 0 ?
                      <AddToCart product={product} /> :
                      
                      // <GetVariations variantAttributes={product.variantAttributes} />
                  } */}

                  <AddToCart product={product} setVariantOptions={setVariantOptions} setEnquiryFormQty={setEnquiryFormQty} />

                  {
                    product.productStatus === 100 &&
                    <div>
                      <Button onClick={() => {
                        // setOpenPopup(true);
                        // document.body.style.overflow = "hidden"; type
                        addToQuoteFunction("get_quote");
                      }}> GET QUOTE </Button>
                      <Button className="add__to__quote" onClick={() => {
                        addToQuoteFunction();
                      }}> <MdFavorite /> ADD TO QUOTE </Button>
                    </div>
                  }

                  {
                    product.productStatus === 1 &&
                    <Button disabled> SOLD OUT </Button>
                  }

                  {
                    product.productStatus === 98 &&
                    <Button disabled> NOT AVAILABLE </Button>
                  }

                </div>

              </div>
              {/* <RelatedProducts products={product.relatedProducts} /> */}
            </div>
          </div>

        </div>
      </div>

    </Fragment>
  )
}

// function GetVariations({ variantAttributes }) {

//   console.log("variantAttribute", variantAttributes);

//   const _variantAttributes = variantAttributes || [];
//   return (
//     <div css={style.variant__enquire__container} className='variant__enquire__container'>
//       {
//         _variantAttributes.map((item, index) => {
//           return (
//             <div key={index} className='variant__enquire__item' style={style.variant__enquire__item}>
//               <div className='variant__enquire__title' style={style.variant__enquire__title}>
//                 {item.name}
//               </div>
//               <div className='variant__enquire__options' style={style.variant__enquire__options}>
//                 {
//                   item.options.map((sItem, sIndex) => {
//                     return (
//                       <div key={sIndex} className='variant__enquire__option' style={style.variant__enquire__option}>
//                         {sItem.value}
//                       </div>
//                     )
//                   })
//                 }
//               </div>
//             </div>
//           )
//         })
//       }
//     </div>
//   )
// }

function PdfViewItem({ pdfViewPath, pdfViewNumPages, pdfViewWidth, pdfViewScale, onDocumentLoadSuccess, closePdfViewer, changePdfViewScale }) {

  const abc = (event) => {
    if (event.scale !== 1) { event.preventDefault(); }
  };

  useEffect(() => {
    document.addEventListener('touchmove', abc, { passive: false });
    return () => {
      document.removeEventListener('touchmove', abc, { passive: false });
    }
  }, []);

  return (
    <Fragment>
      <div className="pdf__viewer__bg" css={style.pdf__viewer__bg}></div>
      <div className="pdf__viewer__view" css={style.pdf__viewer__view}>

        <div className="pdf__viewer__view__bar" css={style.pdf__viewer__view__bar}>
          <div onClick={closePdfViewer} style={{ display: 'inline-block' }}>CLOSE</div>
          {
            pdfViewNumPages &&
            <a className='pdf__viewer__view__bar__pdf__download' href={pdfViewPath} download> DOWNLOAD </a>
          }

          {
            pdfViewNumPages &&
            <div onClick={changePdfViewScale} style={{ position: 'absolute', fontSize: '22px', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} > - / + </div>
          }

        </div>
        <div className="pdf__viewer__view__contetns" css={style.pdf__viewer__view__contetns}>

          {/* <div className='pdf__viewer__view__contetns__des' css={style.pdf__viewer__view__contetns__des} style={{ height: '0px' }}>
            <Document file={pdfViewPath} onLoadSuccess={onDocumentLoadSuccess}></Document>
          </div>
          
          {
            pdfViewNumPages &&
            <div className='pdf__viewer__view__contetns__des' css={style.pdf__viewer__view__contetns__des} >
              {
                pdfViewNumPages.map((item, index) => {
                  return (
                    <Document key={index} file={pdfViewPath}>
                      {
                        item.items.map((sItem, sIndex) => {
                          return (
                            <Page key={sIndex} pageIndex={sItem} scale={pdfViewScale === false ? 1.0 : 2.0} width={pdfViewWidth} className='pdf__viewer__view__page' css={style.pdf__viewer__view__page}>
                              <div className='pdf__viewer__view__index' css={style.pdf__viewer__view__index}>{(sItem + 1) + ' of ' + item.numPages}</div>
                            </Page>
                          )
                        })
                      }
                    </Document>
                  )
                })
              }
            </div>
          } */}

          <div className='pdf__viewer__view__contetns__des' css={style.pdf__viewer__view__contetns__des}>
            
            <Document file={pdfViewPath} onLoadSuccess={onDocumentLoadSuccess}>

              {
                pdfViewNumPages &&
                pdfViewNumPages.map((item, index) => {
                  return (
                    <Page key={index} pageIndex={index} scale={pdfViewScale === false ? 1.0 : 2.0} width={pdfViewWidth} className='pdf__viewer__view__page' css={style.pdf__viewer__view__page}>
                      <div className='pdf__viewer__view__index' css={style.pdf__viewer__view__index}>{(index + 1) + ' of ' + pdfViewNumPages.length}</div>
                    </Page>
                  )
                })
              }

            </Document>

          </div>

        </div>
      </div>
    </Fragment>
  )
}


function getMetaTag(product, mainPhoto) {
  return {
    title: product.productName,
    description: product.metaDesc || product.intro,
    keywords: '',
    heroImageUrl: utils.site.resourcePath(mainPhoto.imageUrl),
    language: 'English',
    country: 'Australia',
    siteName: env.siteName,
    url: utils.site.resourcePath('/product/' + product.productSlug),
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  }
}

// function RelatedProducts({ products }) {
//   //console.log(products)
//   if (!products || products.length === 0) return null;
//   return <div className="productView__relatedProducts" css={style.productView__relatedProducts}>
//     <h3 className="productView__relatedProducts__head__title" > Why not add a small gift? </h3>
//     <div className="productList">
//       {products.map((product, index) =>
//         <ProductTile product={product} key={index} openClikcPopup={true}> </ProductTile>
//       )}
//     </div>
//   </div>
// }