/** @jsx jsx */
import { jsx } from '@emotion/core';
import utils from '../../../utils';
import { SiteLink, useGet } from '../../../components';
import { tileStyle } from './productTileStyle';
import { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import { createPortal } from "react-dom";
import env from '../../../env';
import { AddToCart } from '../product/addToCart';
//import { IoIosStar } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";

function Portal({ children }) {
  const [el] = useState(document.createElement('div'));
  useEffect(() => {
    let mount = document.getElementById("portal-root");
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

export function ProductTile({ product, openClikcPopup = false }) {
  const s = tileStyle;
  const imageUrl = product.thumbnailUrl ? utils.site.resourcePath(product.thumbnailUrl) : '/assets/product-empty.png';
  //const imageUrl = 'https://controlair.stg5.jaba.com.au/sites/controlair/media/products/refrigeration/1.01a-hussmann-rl-freezer-frozen-rendering.png';
  const [openProductViewPopup, setOpenProductViewPopup] = useState(false);


  console.log("product : ---> ", product)

  const productUrl = product.productUrl ? product.productUrl : `/product/${product.productSlug}`;

  function openProductViewPopupF() {
    setOpenProductViewPopup(true);
    document.body.style.overflow = "hidden";
  }

  function closeProductViewPopupF() {
    setOpenProductViewPopup(false);
    document.body.style.overflow = "unset";
  }

  // const getSale = useCallback(() => {

  // }, []);

  return (
    <Fragment>
      {
        openProductViewPopup &&
        <Portal>
          <ProductViewPopup s={s} closeProductViewPopupF={closeProductViewPopupF} product={product} />
        </Portal>
      }
      <div css={[s.tile]} className='product__tile__item'>
        {
          openClikcPopup === false ?
            <SiteLink css={s.tileLink} to={productUrl}>
              <TileContetns product={product} s={s} imageUrl={imageUrl} />
            </SiteLink> :
            <div css={s.tileLink} onClick={() => {
              openProductViewPopupF();
            }}>
              <TileContetns product={product} s={s} imageUrl={imageUrl} />
            </div>
        }
      </div>
    </Fragment>
  )
}

function TileContetns({ product, s, imageUrl }) {

  const saleCheck = useMemo(() => {
    let checkSale = false;
    if (product.productCategories && product.productCategories.length > 0) {
      for (let i in product.productCategories) {
        if (product.productCategories[i].categoryName === 'On Sale') {
          checkSale = true;
          break;
        }
      }
    }
    return checkSale;
  }, [product]);
  
  return (

    <div style={{ position: 'relative', height: '100%' }}>
      <div css={s.container}>
        <div css={s.prdImage}>
          <div css={s.bg} className='product__tile__bg' style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
        </div>

        <div css={s.name}>
          {product.productName}
        </div>

        <div css={s.des}>
          {product.shortDesc}
        </div>

        <div css={s.button}>
          <button className='btn btn-primary'>
            SHOP NOW
          </button>
        </div>
      </div>

      {
        product && product.checkNew &&
        <div css={s.new}>
          NEW
        </div>
      }

      {
        product && saleCheck === true &&
        <div css={s.sale}>
          SALE
        </div>
      }

    </div>


  )
}

function ProductViewPopup({ s, closeProductViewPopupF, product }) {

  const get = useGet();

  useEffect(() => {
    const url = `/api/product/getProduct/${product.productSlug}`;
    get.send(env.apiBase + url);
    // eslint-disable-next-line
  }, []);

  const data = get.response;

  return (
    <Fragment>
      <div css={s.portal__background} className='portal__background' onClick={closeProductViewPopupF}></div>
      <div css={s.portal__popupcontainer} className='portal__popupcontainer'>
        <div css={s.related__product__head} className='related__product__head'>
          <img src="/assets/logos/logo.png" alt="logo" />
          <div style={{ position: 'absolute', top: '50%', right: '0px', transform: 'translateY(-50%)', color: 'white', fontSize: '2rem', cursor: 'pointer' }} onClick={closeProductViewPopupF}>
            <AiOutlineClose />
          </div>
        </div>
        {
          data ?
            <div css={s.related__product__contents} className='related__product__contents row'>
              <div className='col-12 col-md-5' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                <div css={s.related__product__img} className="related__product__img">
                  {/* <img src={data.thumbnailUrl} alt={`img`} /> */}
                  <img src={data.thumbnailUrl} alt={`img`} />
                </div>
              </div>
              <div className='col-12 col-md-7' style={{ backgroundColor: 'white' }}>
                <h1 className="related__product__title" style={{ textTransform: 'uppercase' }} css={s.related__product__title}>
                  <strong>{data.productName}</strong>
                </h1>
                <div className='related__product__desc' css={s.related__product__desc} dangerouslySetInnerHTML={{ __html: data.description }}></div>
                {
                  data.productStatus === 0 && <AddToCart product={data} />
                }
              </div>
            </div> :
            <div css={s.related__product__contents} className='related__product__contents row'>
              <div className='col-12'> Loading ... </div>
            </div>
        }

      </div>
    </Fragment>
  )

}