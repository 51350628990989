/** @jsx jsx */
import { jsx } from '@emotion/core'
//import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';
import { getStyle } from './style';

export function BannerItem(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  
  const overlayColor = (props && props.cmsOption && props.cmsOption.overlayColor) || 'transparent';
  const overlayOpacity = (props && props.cmsOption && props.cmsOption.overlayOpacity) || '1.0';

  const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, 'ImageUrl'));

  const smallCaptionTop = cmsUtils.payload(item, 'SmallCaptionTop') || "";
  const bigCaptionMiddle = cmsUtils.payload(item, 'BigCaptionMiddle') || "";
  const smallCaptionBottom = cmsUtils.payload(item, 'SmallCaptionBottom') || "";

  const captionButton = cmsUtils.payload(item, 'CaptionButton') && cmsUtils.payload(item, 'CaptionButton') !== "" ? cmsUtils.payload(item, 'CaptionButton') : "";
  const linkUrl = cmsUtils.payload(item, 'LinkUrl2') || cmsUtils.payload(item, 'LinkUrl');
  
  const cssClass = utils.classNames('cms_item', 'banner-item', 'bannerSize', item.cssClass || item.anchorName || '');

  const style = getStyle({overlayColor : overlayColor, overlayOpacity : overlayOpacity});

  return (
    <div css={style.single__banner__bg} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName} style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl) }}>
      {
        (linkUrl && linkUrl !== '') ?
        <SiteLink className='banner__item__link' to={linkUrl}>
          <BannerItemContetns
            smallCaptionTop={smallCaptionTop}
            bigCaptionMiddle={bigCaptionMiddle}
            smallCaptionBottom={smallCaptionBottom}
            captionButton={captionButton}
          />
        </SiteLink> :
        <BannerItemContetns
          smallCaptionTop={smallCaptionTop}
          bigCaptionMiddle={bigCaptionMiddle}
          smallCaptionBottom={smallCaptionBottom}
          captionButton={captionButton}
        />
      }
    </div>
  );

}

function BannerItemContetns({ smallCaptionTop, bigCaptionMiddle, smallCaptionBottom, captionButton }) {
  return (
    <div className='banner__item__container'>
      {
        smallCaptionTop && smallCaptionTop !== '' &&
        <div className='banner__item__small__caption__top'>
          {smallCaptionTop}
        </div>
      }
      {
        bigCaptionMiddle && bigCaptionMiddle !== '' &&
        <div className='banner__item__big__caption__middle'>
          {bigCaptionMiddle}
        </div>
      }
      {
        smallCaptionBottom && smallCaptionBottom !== '' &&
        <div className='banner__item__small__caption__bottom'>
          {smallCaptionBottom}
        </div>
      }
      {
        captionButton && captionButton !== '' &&
        <div className='banner__item__caption__button'>
          <button className='btn btn-primary'>{captionButton}</button>
        </div>
      }
    </div>
  )
}